// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require("jquery")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

import 'bootstrap'
// import $ from 'jquery';

$( document ).ready(function() {
  
  if (typeof upcomingFeed !== 'undefined' && upcomingFeed != '') {
    console.log(upcomingFeed);
    fetch(upcomingFeed)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Error retrieving data.');
      }
    })
    .then(data => listUpcomingGigs(data))
    .catch((error) => {
      $(".loading-spinner").html("<i class='fas fa-exclamation-circle'></i> " + error);
    });
  }

  $(".label-select-row label").click(function() {
    $(this).children("input").focus();
  });

  $("[data-contact-type='artist_select']").hide();

  $("[data-contact-form-func='enquiry-type']").change(function() {
    if($(this).data("contact-query") == 'has-artists') {
      $("[data-contact-type='artist_select']").slideDown();
    }
    else {
      $("[data-contact-type='artist_select']").slideUp();
    }
  });
  
  $(".playback-controls").show();
  
  var video = document.getElementById('video');
  var playpause = document.getElementById('playpause');
  var mute = document.getElementById('mute');
  
  var changeButtonState = function(type) {
     // Play/Pause button
     if (type == 'playpause') {
        if (video.paused || video.ended) {
           playpause.setAttribute('data-state', 'play');
           playpause.innerHTML = "<i class='fas fa-fw fa-play'></i>";
        }
        else {
           playpause.setAttribute('data-state', 'pause');
            playpause.innerHTML = "<i class='fas fa-fw fa-pause'></i>";
        }
     }
     // Mute button
     else if (type == 'mute') {
        mute.setAttribute('data-state', video.muted ? 'unmute' : 'mute');
        mute.innerHTML = ('data-state', video.muted ? "<i class='fas fa-fw fa-volume-mute'></i>" : "<i class='fas fa-fw fa-volume'></i>");
     }
  }
  
  video.addEventListener('play', function() {
     changeButtonState('playpause');
  }, false);
  video.addEventListener('pause', function() {
     changeButtonState('playpause');
  }, false);
  mute.addEventListener('click', function(e) {
     video.muted = !video.muted;
     changeButtonState('mute');
  });
  
  // Add events for all buttons			
  playpause.addEventListener('click', function(e) {
    if (video.paused || video.ended) video.play();
    else video.pause();
  });			
  
});

function listUpcomingGigs(data) {
  if(data) {
    // console.log(data);
    $(".upcoming-shows-list .loading-spinner").hide();
    let numberOfGigs = 0;
    $.each(data, function(i, gig) {
      let gigEventImage = $("");
      if(gig.event_image_url || gig.cover_image_url) {
        numberOfGigs++;
        gigEventImage = $(`
          <a href="${gig.url}" class="event-image-container">
            <img class='event-image' src='${ gig.event_image_url ? gig.event_image_url : ( gig.cover_image_url ? gig.cover_image_url : "" ) }' alt='Artwork for ${gig.title}'>
          </a>
        `);
      }
      let gigDate = new Date(gig.start_time);
      
      gigDate = formatDate(gigDate);
      
      let gigStatus = "";
      
      switch (gig.status) {
        case 'postponed':
        gigStatus = "<span class='badge badge-warning text-dark'>Postponed</span> &nbsp;";
          break;
        case 'cancelled':
          gigStatus = "<span class='badge badge-danger'>Cancelled</span> &nbsp;";
          break;
        default:
          break;
      }
      
      let gigHtmlLink = $(`
        <div class='gig-info-block'>
          <div class="gig-info">
            <h3 class='h5'><a href="${gig.url}">${gig.title}</a></h3>
            <span class="gig-details">${gigStatus} ${gigDate} <br> ${gig.location}${ gig.country ? ", " + gig.country : ""}</span>
          </div>
        </div>
      `).prepend(gigEventImage);
      
      let gigTicketLink = $("");
      if(gig.ticket_url) {
        gigTicketLink = $(`<li class="action"><a class="btn btn-sm btn-secondary" href="${gig.ticket_url}">Get Tickets</a></li>`);
      }
      let gigEventLink = $("");
      // if(gig.eventlink || gig.fburl) {
      //   let gigEventLinkSelect = ( gig.eventlink ? gig.eventlink : (gig.fburl ? gig.fburl : "") )
      //   gigEventLink = $(`<li class="action"><a class="btn btn-sm btn-secondary" href="${ gigEventLinkSelect }">Event Info</a></li>`);
      // }
      
      let gigButtons = $(`<ul class="actions"></ul>`).append(gigEventLink).append(gigTicketLink);
      
      let gigHtml = $("<li class='gig'></li>").attr('id', 'gig-' + gig.slug).append(gigHtmlLink).append(gigButtons);
      $(".upcoming-shows-list").append(gigHtml);
      
      // $(".upcoming-shows-list").append(`
      //   <li>
      //     <a href='${gig.url}' class='gig-info'>
      //       <h3 class='h5'>${gig.title}</h3>
      //       <span>${gig.date}</span>
      //     </a>
      //   </li>
      //   `);
    });
    if(numberOfGigs < 1) {
      $(".upcoming-shows-list").append("<li><i class='fas fa-info-circle'></i> There are no upcoming dates announced.</li>");
    }
  }
}

function formatDate(d) {
  const year = d.getFullYear() // 2019
  const date = d.getDate() // 23''
  
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  
  const monthName = months[d.getMonth()]
  
  const days = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ]
  
  const dayName = days[d.getDay()]
  
  const formatted = `${d.getHours()}:${String(d.getMinutes()).padStart(2, "0")} &nbsp; ${dayName} ${date} ${monthName} ${ new Date().getFullYear() == year ? "" : year }`
  
  return formatted
}